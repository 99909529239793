<template>
  <div v-if="listData.length > 0">
    <!--<div
      style="
        z-index: 50000;
        position: fixed;
        bottom: 0;
        left: 0;
        color: #fff;
        background: blue;
        padding: 10px;
      "
    >
      {{ this.test.innerHeight }}<br />
      {{ this.test.scrollY }}<br />
      {{ this.test.offsetHeight }}<br />
    </div>-->
    <div class="key-visual">
      <div class="mask">
        <div class="contents">
          <div class="txt">
            We are digital experts<br />
            who will make<br />
            your business greater.
          </div>
          <router-link :to="`/works/${listData[0].id}`" class="key-button">See works</router-link>
        </div>
        <div
          class="bg"
          :style="{
            backgroundImage: `url(${imgUrl(listData[0].moDetailImagePhysicalName)})`
          }"
        ></div>
      </div>
    </div>
    <div class="work-wrap">
      <div class="contents">
        <p class="description">
          하나의 프로젝트를 완성하기 위해<br />
          디테일한 부분까지 집중하고 몰입하여<br />
          <span class="fc-red"> 완벽함을 만들어 냅니다.</span>
        </p>
      </div>
      <!--<div class="tab-wrap">
        <ul class="tab-list">
          <li v-for="(item, index) in tabList" :key="index" :class="{active: tabIdx === item.seq}">
            <a href="#">{{ item.name }}</a>
          </li>
        </ul>
      </div>-->
      <div class="contents">
        <ul class="work-list">
          <li
            v-for="(item, index) in listData"
            :key="item.id"
            :class="{txtTime: !item.moBigImagePhysicalName}"
          >
            <router-link :to="`/works/${item.id}`">
              <div class="thumb">
                <img :src="imgUrlIndex(item, index)" alt="" />
              </div>
              <div class="info">
                <i class="circle" />
                <div class="title">{{ item.projectServiceName }}</div>
                <div class="desc">{{ item.projectType }}</div>
                <span class="more">Read More</span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  import {Power0, TweenMax} from "gsap";
  import {commonMethods} from "@/utils/common-methods";
  import {mapGetters} from "vuex";
  import {apiWorksList} from "@/api";
  export default {
    name: "worksList",
    mixins: [commonMethods],
    data() {
      return {
        scene: null,
        page: 0,
        size: 10,
        keyword: "",
        tabIdx: 0,
        tabList: [
          {
            name: "All",
            seq: 0
          },
          {
            name: "커머스",
            seq: 1
          },
          {
            name: "금융",
            seq: 2
          },
          {
            name: "뷰티",
            seq: 3
          },
          {
            name: "커머스2",
            seq: 4
          },
          {
            name: "금융2",
            seq: 5
          },
          {
            name: "뷰티2",
            seq: 6
          }
        ],
        listData: [],
        loading: false,
        test: {
          innerHeight: 0,
          scrollY: 0,
          offsetHeight: 0
        }
      };
    },
    props: ["keyVisualMove"],
    computed: {
      ...mapGetters(["GE_CONT_HEIGHT"])
    },
    watch: {
      GE_CONT_HEIGHT() {
        if (this.$el.offsetParent) {
          this.init();
        }
      }
    },
    activated() {
      this.init();
    },
    methods: {
      infiniteScroll() {
        if (
          this.page < this.totalPage &&
          !this.loading &&
          window.innerHeight + window.scrollY >= document.body.offsetHeight - 10
        ) {
          this.page = this.page + 1;
          this.fetchData();
        }
      },
      imgUrlIndex(item, index) {
        const a = process.env.VUE_APP_API_URL;
        const b = index % 3 === 1 ? item.moSmallImagePhysicalName : item.moSmallImagePhysicalName; // moBigImagePhysicalName
        return a + b;
      },
      init() {
        this.scene?.destroy(true);
        const keyVisual = document.querySelector(".key-visual");
        if (!keyVisual) return;
        const tween = TweenMax.to(keyVisual.querySelector(".bg"), 1, {
          y: keyVisual.clientHeight,
          ease: Power0.easeInOut
        });
        const tween2 = TweenMax.to(keyVisual.querySelector(".txt"), 1, {
          y: keyVisual.clientHeight + keyVisual.querySelector(".txt").clientHeight,
          ease: Power0.easeInOut
        });
        const tween3 = TweenMax.to(keyVisual.querySelector(".key-button"), 1, {
          y: keyVisual.clientHeight + 200,
          ease: Power0.easeInOut
        });
        this.scene = this.$scrollmagic
          .scene({
            triggerElement: keyVisual,
            triggerHook: 0,
            duration: keyVisual.clientHeight * 2
          })
          .setTween([tween, tween2, tween3]);
        this.$scrollmagic.addScene(this.scene);
      },
      async fetchData() {
        try {
          const {
            data: {data: response}
          } = await apiWorksList({
            page: this.page,
            size: this.size,
            keyword: this.keyword
          });
          this.totalPage = response.totalPages;
          this.listData = this.listData.concat(response.content);
          console.log(1);
          this.$nextTick(() => {
            this.init();
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
    created() {
      this.fetchData();
      window.addEventListener("scroll", this.infiniteScroll);
    },
    deactivated() {
      window.removeEventListener("scroll", this.infiniteScroll);
    },
    destroyed() {
      window.removeEventListener("scroll", this.infiniteScroll);
      this.scene?.destroy(true);
    }
  };
</script>
<style lang="scss" scoped>
  .key-visual {
    position: relative;
    height: 100vh;
    min-width: $globalWidth;
    .mask {
      height: 100%;
      position: relative;
      overflow: hidden;
    }
    .contents {
      z-index: 2;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
    }
    .txt {
      @include montserrat;
      font-size: 36px;
      line-height: 45px;
      font-weight: bold;
      color: $red;
    }
    .key-button {
      flex: 0 0 auto;
      display: block;
      border: none;
      border-radius: 50%;
      background: $red;
      font-size: 13px;
      @include montserrat();
      color: #fff;
      height: 84px;
      width: 84px;
      text-align: center;
      line-height: 84px;
      margin-top: 60px;
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      min-width: $globalWidth;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
  }
  .work-wrap {
    padding: 55px 0 40px;
    .description {
      font-size: 18px;
      line-height: 28px;
      color: #a0a0a0;
      font-weight: bold;
      span {
        color: $red;
      }
    }
    .tab-wrap {
      margin-top: 55px;
      overflow-x: scroll;
    }
    .tab-list {
      display: flex;
      margin: 0 20px;
      li {
        border-radius: 20px;
        color: #333;
        font-size: 13px;
        a {
          @include montserrat;
          display: block;
          min-width: 76px;
          padding: 0 15px;
          box-sizing: border-box;
          line-height: 36px;
          text-align: center;
        }
        &.active {
          background: $red;
          color: #fff;
          font-weight: bold;
        }
      }
    }
    .work-list {
      margin-top: 40px;
      li {
        margin-top: 80px;
        &:first-child {
          margin-top: 0;
        }
        // &:nth-child(2n + 1) {
        //   margin-right: 40px;
        // }
        // &:nth-child(2n) {
        //   margin-left: 40px;
        // }
      }

      a {
        display: block;
      }
      .thumb {
        display: block;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        img {
          width: 100%;
          vertical-align: top;
        }
      }
      .circle {
        display: block;
        width: 10px;
        height: 10px;
        margin-top: 20px;
        border-radius: 100%;
        background: $red;
      }
      .title {
        margin-top: 15px;
        @include montserrat;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0;
        line-height: 18px;
        font-weight: bold;
      }
      .desc {
        margin-top: 8px;
        @include montserrat;
        font-size: 13px;
        line-height: 20px;
        color: #a0a0a0;
      }
      .more {
        margin-top: 25px;
        display: inline-flex;
        border-bottom: 1px solid #000;
        @include montserrat;
        font-size: 13px;
        color: #000;
        line-height: 19px;
      }
    }
  }
</style>
